/* PRODUCT PAGE */
.images-container {
  gap: $spacer * 1.25;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 100%;
  justify-content: stretch;
  align-items: stretch;
  .slick-list {
    min-height: 100%!important;
  }
}

.images-container:hover .btn-zoom {
  display: block;
}

.products-imagescover {
  min-width: 0;
  flex-basis: 100%;
}
.product-img:not(:first-child) .rc {
  display: none;
}
.product-thumbs {
  flex-basis: 15%;
  width: auto;
  .slick-current .product-thumb{
    border:solid 2px $primary;
    border-radius: $border-radius-md;
    overflow: hidden;
  }
}
.product-thumb {
  margin-bottom: $spacer * 0.75;
  @include transition-fast;
  cursor: pointer;
}

.btn-zoom {
  position: absolute;
  right: $spacer;
  bottom: $spacer;
  cursor: pointer;
  display: none;
  padding: 0;
  line-height: 1;
  & .material-icons {
    font-size: 3 * $font-size-base;
  }
}

.label-color {
  line-height: 1;
  margin: 0;
  gap: $spacer/4;
  align-items: center;
  padding-bottom: $spacer * 2;
  max-width: $spacer * 5.5;
  position: relative;
  &.checked {
    .sr-only {
      color: $primary;
      font-weight: $weight-medium;
    }
  }
}
.product-variants-item {
  margin-bottom: $spacer * 1.5;
  .color {
    width: $spacer * 5.5;
    height: $spacer * 5.5;
    display: flex;
    justify-content: center;
    border: 1px solid $gray-100;
    border-radius: $btn-radius;
    background-size: cover;
    cursor: pointer;
    &.zoomed {
      width: $spacer * 9;
      height: $spacer * 9;
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      right: -$spacer * 1.5;
      top: -$spacer * 9.5;
      z-index: 99;
      display: none;
    }
  }
  &.group_4 .color {
    background-size: 70%;
    background-color: #fff;
    background-position: center;
  }

  .rohove-provedeni-img {
    position: absolute;
    right: -$spacer * 1.5;
    top: -$spacer * 10;
    border: 1px solid $gray-100;
    border-radius: $btn-radius;
    box-shadow: $box-shadow;
    overflow: hidden;
    display: none;
    cursor: pointer;
  }

  a.info-btn {
    background: $primary;
    width: $spacer;
    height: $spacer;
    margin-left: $spacer * 0.2;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: $font-size-base * 0.65;
    border-radius: 100%;
    cursor: pointer;
  }
}

.product-variants {
  .label {
    text-transform: uppercase;
    font-size: $font-size-base * 1.125;
  }

  .sr-only {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0;
    overflow: visible;
    white-space: break-spaces;
    text-align: center;
    font-size: $font-size-base * 0.75;
    font-weight: $weight-regular;
    text-transform: lowercase;
    max-width: 100%;
    margin-top: $spacer/2;
  }
}

.material {
  display: flex;
  gap: $spacer/2;
}

.radio-btns {
  display: flex;
  gap: $spacer;
  margin: $spacer * 0.75 0;
  .custom-control {
    display: flex;
  }

  .custom-control-label {
    font-size: $font-size-base * 0.75;
    font-weight: $weight-medium-regular;
    color: $gray-900;
    &.checked {
      color: $primary;
      &::after {
        background: $primary;
        border: solid $white 2px;
        border-radius: 100%;
        width: $spacer * 0.75;
        height: $spacer * 0.75;
        left: -$spacer * 1.35;
        top: $spacer * 0.18;
        background-image: none !important;
      }
    }
  }
}
.input-color + span {
  position: relative;
  @include transition-fast;
  &:after {
    content: "";
    position: absolute;
    right: $spacer * 0.5;
    top: $spacer * 0.5;
    width: $spacer * 1.5;
    height: $spacer * 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    background: white url(/themes/norman-theme/assets/img/check.svg) center center no-repeat;
    background-size: 50%;
    border-radius: $btn-radius;
    @include transition-fast;
  }
}

.input-color:checked + span,
.input-radio:checked + span {
  border: solid $primary 3px;
  &::after {
    opacity: 1 !important;
  }
}
.input-color:hover + span,
.input-radio:hover + span {
  &:after {
    opacity: 0.25;
  }
}

#product {
  .product-img {
    border: solid 1px $gray-200;
    border-radius: $border-radius-lg;
    overflow: hidden;
  }
  .product-prices {
    background-color: $gray-200;
    padding: $spacer $spacer * 2;
    border-radius: $border-radius-md;
    display: flex;
    .product-quantities {
      display: none;
    }
  }

  .product-price {
    font-weight: $weight-medium;
    &.has-discount {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: $spacer;

      .regular-price {
        font-size: $font-size-base * 0.75;
        text-decoration: line-through;
        color: $gray-500;
      }
    }

    .current-price-display {
      font-size: $font-size-base * 1.125;
      color: $gray-900;
    }
  }

  .product-without-taxes {
    margin-top: $spacer/2;
    color: $gray-500;
    font-weight: $weight-regular;
    font-size: $font-size-base * 0.875;
  }

  #content-wrapper {
    .add-to-cart {
      width: max-content;
      height: auto;
      padding: $spacer $spacer * 2;
      border-radius: $btn-radius;
      color: $white;
      font-weight: $weight-medium;
      text-transform: uppercase;
      text-align: center;

      &:disabled:before {
        background: $gray-600;
      }

      &:disabled:after {
        display: none;
      }
      svg {
        margin-right: $spacer;
        width: $spacer * 1.5;
        height: $spacer * 1.5;
      }
    }

    .pspc-wrp {
      left: $spacer * 1.5;
      top: $spacer;
    }
  }
}

.pspc-wrp {
  position: absolute;
}

.product-accessories {
  background-color: $off-white;
}

.query-block {
  .card {
    background: none;
  }

  .products-section-title {
    text-align: left;
  }

  .hi-faq-item {
    border: none;
    border-radius: $border-radius-md;
    box-shadow: $header-shadow;
  }
  .hi-faq-question a {
    font-weight: $weight-medium-regular;
    color: $gray-600;
  }
  .hi-faq-answer {
    font-size: $font-size-base * 0.875;
    color: $gray-500;
  }
}
//product add to cart
.btn-add-to-cart {
  font-weight: $weight-regular;
  letter-spacing: 1px;
}

a.nav-link.active {
  position: relative;
  overflow: visible;
  &::before {
    content: "";
    background: $gray-100;
    width: $spacer * 2;
    height: $spacer * 2;
    position: absolute;
    transform: rotate(45deg);
    bottom: -2.75rem;
    left: calc(50% - 1rem);
    z-index: 0;
  }
}

//product tabs
.product-tabs {
  border-top: solid 1px $gray-200;
  border-radius: 0;
  margin-top: $spacer * 2.5;
  padding-top: $spacer * 2.5;
  .nav-tabs {
    gap: $spacer/2;
    font-size: $font-size-base * 0.9;
    border: none;
    margin-bottom: $spacer !important;
    overflow: visible;
    a svg {
      width: $spacer;
      height: $spacer;
      margin-right: 0$spacer/4;
    }
  }
  .tab-content {
    background: $gray-200;
    border-radius: $border-radius-lg;
    padding: $spacer * 2;
    z-index: 9;
  }
}
.card--reassurance {
  box-shadow: $card-box-shadow;
}

#product .accessories-block {
  .products {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .product__card-desc {
    margin-bottom: $spacer * 0.75;
  }

  .add-to-cart {
    width: 100%;
    font-weight: $weight-medium-regular;
  }

  .product-title a {
    font-size: $font-size-base * 0.875;
    color: $primary;
    margin-bottom: 0;
  }
  .product-price-and-shipping {
    margin-top: $spacer/2;
  }
}

.product-attachments {
  a {
    font-weight: $weight-medium-regular;
    svg {
      width: $spacer;
      margin-right: $spacer/2;
      fill: $primary;
      position: relative;
      bottom: 1px;
    }
  }
}

// modal add to cart
.cart-content {
  padding: $spacer;
  background: theme-color("light");
}

//product pack
.pack-product-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $spacer;
  font-size: $font-size-sm;
}
.mask {
  width: 55px;
}
.thumb-mask {
  padding: $spacer 0;
}
.pack-product__img {
  border: 1px solid $border-color;
}
.pack-product-name {
  flex-grow: 1;
  padding: 0 $spacer * 2;
}
.pack-product-quantity {
  border-left: 1px solid $border-color;
}
.pack-product-price {
  padding-right: $spacer/2;
}
.pack-product-quantity {
  display: flex;
  align-items: center;
  align-self: stretch;
  display: flex;
  align-items: center;
  align-self: stretch;
  padding-left: $spacer;
}

//modal cart
.modal-cart__image {
  margin-right: $spacer;
  max-width: 200px;

  height: auto;
}
.modal-cart__name {
  color: $primary;
  font-weight: $font-weight-bold;
}
.product-total {
  font-weight: $font-weight-bold;
}
.cart-content {
  > p:not(:last-child) {
    margin-bottom: $spacer/2;
  }
}
@include media-breakpoint-mobile {
  .modal-cart__image {
    max-width: 120px;
  }
  .blockcart-modal {
    .divide-right {
      display: flex;
      justify-content: center;
    }
    .modal-footer {
      position: fixed;
      bottom: 0;
      background: $white;
      justify-content: center;
      left: 0;
      width: 100%;
      box-shadow: 0px -0.25rem 0.5rem rgba($black, 0.15);
    }
    .modal-body {
      margin-bottom: ($btn-padding-y * 2) + ($btn-font-size * $btn-line-height) + $modal-inner-padding * 2;
    }
  }
}
@include media-breakpoint-down(xs) {
  .modal-cart__image {
    max-width: 70px;
  }
}
//spinner add to cart
.btn-add-to-cart {
  .btn-add-to-cart__spinner {
    width: $spinner-width-addtocart;
    height: $spinner-height-addtocart;
    border-width: $spinner-borderwidth-addtocart;
    display: none;
  }
  &.is--loading {
    .btn-add-to-cart__spinner {
      display: inline-block;
    }
    .btn-add-to-cart__icon {
      display: none;
    }
  }
}

.products-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
.pack-miniature-item {
  margin-bottom: $spacer / 2;
}
.ratio1_1 {
  display: block;
}
.pack-product-name {
  margin: 0 $spacer * 1.5;
  flex-grow: 1;
}
.product__card-img {
  position: relative;
}
.product__card-desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.quantity__label {
  margin-bottom: 0;
  margin-top: $spacer;
}

.product-add-to-cart-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: $spacer * 1.5;
}

.product-add-to-cart {
  .qty {
    margin: 0 $spacer;
    .input-group {
      border-radius: $border-radius-lg;
      box-shadow: none;
    }

    #quantity_wanted {
      height: 60px;
      min-width: $spacer * 6;
      padding-right: $spacer * 3;
      border-top-right-radius: $border-radius-lg;
      border-bottom-right-radius: $border-radius-lg;
    }

    .input-group-btn {
      position: absolute;
      height: 50%;
      width: $spacer * 2;
      right: 0;
      margin: 0;
      padding: 0;
      border-left: 1px solid $gray-200;
      z-index: 9;
      justify-content: center;
      &::before,
      &::after {
        display: none;
      }
      .btn {
        padding: 0;
        width: 100%;
        box-shadow: none;
        &:hover {
          color: $primary;
        }
      }
      &.input-group-append {
        top: 0;
      }
      &.input-group-prepend {
        bottom: 0;
      }
    }

    .js-touchspin {
      font-size: 0;
      &::before {
        content: "";
        width: 9px;
        height: 9px;
        position: absolute;
        border-left: solid 2px $gray-400;
        border-top: solid 2px $gray-400;
        transform: rotate(45deg);
        top: 14px;
      }

      &.bootstrap-touchspin-down::before {
        transform: rotate(225deg);
        top: 7px;
      }
    }
  }

  .product-minimal-quantity {
    margin: 0;
  }
}
.product-customization {
  margin-bottom: $spacer * 1.5;
}
.product-discounts {
  margin-bottom: $spacer * 1.5;
}
.product__quantity {
  margin-bottom: 0;
}
.sort-by-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.total__item-show {
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
}
.variant-links {
  display: flex;
  justify-content: center;
}
.product__download {
  text-transform: uppercase;
}
.card--address {
  height: 100%;
  text-align: center;
  justify-content: center;
}
.invoice__address {
  margin-top: $spacer;
}
.invoice-address {
  height: 100%;
  text-align: center;
  justify-content: center;
}

.infinitescroll-bottom-message {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: $spacer * 2;
  .infinitescroll-button {
    background-color: $white;
    padding: 0 ($spacer * 2);
    &:before {
      left: 0;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      height: 1px;
      background-color: $gray-200;
      z-index: -1;
    }
  }
}

.section-border {
  border-bottom: 1px solid $gray-200;
}

.product-attributes-wrapper {
  display: flex;
  gap: $spacer $spacer * 2;
  background: $gray-200;
  padding: $spacer * 0.75 $spacer * 1.5;
  border-radius: $btn-radius;
}

.product-additional-wrapper {
  display: flex;
  flex-direction: column;
  font-weight: $weight-medium;
  gap: $spacer/2;
  margin: $spacer * 1.75 0 $spacer * 2;
  text-decoration: underline;
  span {
    position: relative;
    left: $spacer;
    &::before {
      content: "+";
      position: absolute;
      left: -$spacer * 0.8;
    }
  }
}

.product-attribute-box {
  font-weight: $weight-medium;
  font-size: $font-size-base * 0.875;
  position: relative;
  padding: $spacer/4 0;
  color: $gray-900;
  &:not(:last-of-type):before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    background: $gray-300;
    right: -$spacer;
    top: 0;
  }
}

.tab-pane .product-attribute-box:not(:last-of-type):before {
  display: none;
}

.product-colors {
  gap: $spacer * 1.5;
}

.color-name {
  text-align: center;
  font-size: $small-font-size;
  color: $primary;
  font-weight: 500;
}
.product-footer {
  margin-top: $spacer * 2;
  background-color: $gray-200;
  @include border-radius($border-radius-lg);
  padding: $spacer * 2;
}

.product-footer-usps {
  color: $success;
  font-weight: $weight-medium;
  display: flex;
  gap: $spacer;
  padding-bottom: $spacer * 1.25;
  margin-bottom: $spacer * 1.25;
  border-bottom: 1px solid $gray-300;
  font-size: $font-size-base * 0.8;

  > span {
    flex-basis: calc(100% / 3);
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacer * 0.75;
  }

  svg {
    max-width: $spacer * 1.15;
    max-height: $spacer * 1.15;
    width: 100%;
    path,
    rect {
      fill: $success;
    }
  }
}
.product-availability {
  &.available {
    color: $success;
  }
  &.alert {
    color: $secondary;
  }
  &.unavailable {
    color: $danger;
  }
}

.tax-shipping-delivery-label {
  font-weight: $weight-medium;
}

.product-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.625 * $spacer;
  > a,
  button {
    &:before {
      box-shadow: inset 0 0 0 1px $gray-300 !important;
    }
  }
}

.material-icons.text-success {
  font-size: 22.24px;
  font-weight: $weight-bold;
}

.back-button {
  text-transform: uppercase;
  padding: $spacer * 0.75 $spacer * 1.5;
  font-size: $font-size-base * 0.75;
  svg {
    height: $small-font-size;
    overflow: visible;
    fill: $primary;
    margin-right: $spacer/2;
    position: relative;
    bottom: 1px;
  }
}
