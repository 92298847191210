.category-header-top {
    background: url("/themes/norman-theme/assets/img/norman-krby-kategorie.jpg");
    color: $white;
    position: relative;
    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: transparentize($black, 0.45);
        z-index: 1;
    }

    .container {
        position:relative;
        z-index:2
    }
    .main-title {
        margin-bottom: $spacer;
    }

    p {
        font-size: $font-size-base * 0.875;
        line-height: 1.6em;
        max-width:$spacer*50;
    }

    h3 {
        font-size: $font-size-base;
        margin: $spacer * 1.75 0 $spacer;
        font-weight: $weight-medium-regular;
    }

    .buttons {
        gap: $spacer/2 $spacer;
        flex-wrap: wrap;
        a {
            font-size: $font-size-base;
            padding-right: $spacer*2.5;
            text-align: left;
        }
    }
}


#category {
    .block-category {
        margin-bottom: $spacer*2;
        .main-title {
            color:$gray-500;
        }
    }
}


.category-top-menu {
    padding-top: $spacer*1.5;
    padding-bottom:$spacer*2;
}

.category-title {
    font-size: $font-size-base*0.875;      
    font-weight: $weight-medium;
    text-transform: uppercase;
    margin-bottom: $spacer*0.75;
}


.category-menu {
    display: flex;
    gap: $spacer/2;
    flex-wrap: wrap;

    
    li.category-item {
        display: flex;
        &.active a {
            background-color: $primary;
            border-color: $primary;
            color: $white;
            font-weight: $weight-medium;
        }
    }

    a {
        padding: $spacer*.75 $spacer*2 $spacer*0.65;
        border: solid 1px $gray-300;
        border-radius: $btn-radius;
        text-transform: uppercase;
        font-size: $font-size-base*0.75;
        color:$black;
        @include transition-fast;
        &:hover {
            background: $primary;
            border-color: $primary;
            color: $white;
        }
    }
}