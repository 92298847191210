header {
  .header-nav__round-icon {
    width: $size-base;
    height: $size-base;
    @include border-radius(50%);
    @include flex-center;
    position: relative;
    @include transition(0.3s ease);
    background: $gray-100;
    z-index: 21;
  }

  .header__right-icon,
  .header-nav__round-icon a {
    color: $black;
  }

  .header-nav__round-icon.cart-icon {
    background: $primary;
  }

  .cart-icon .header__right-icon {
    color: $white;
    svg {
      margin-right:$spacer*0.75
    }
  }

  .header-nav__round-icon a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include border-radius(50%);
  }

  
  /* Blockcart */

  .blockcart__count {
    position: absolute;
    top: -2px;
    left: 5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(25%, -25%);

    background: $black;
    color: $white;
    @include border-radius(50%);
    @include transition(0.15s ease);

    // &:empty {
    //   opacity: 0;
    // }
  }

  .inactive .blockcart__count {
    opacity: 0;
  }

  .inactive .shopping-cart a {
    pointer-events: none;
  }

  @include media-breakpoint-tablet {
    .header-nav__round-icon {
      background-color: transparent;
    }
    .blockcart {
      &.hovered {
        pointer-events: none;
      }
      .shopping-cart a {
        pointer-events: none;
      }
    }
  }
}
