header {
  background-color: $header-bg;
  position: sticky;
  top: -1px;
  z-index: 21;
  flex-wrap: wrap;
  @include transition-fast;
}

@include media-breakpoint-down(xl) {
  .header__logo,
  .header__logo--checkout {
    max-width: 50%;
  }
  .header__logo--checkout {
    margin: 0 auto;
  }
  .header__right {
    flex-grow: 1;
    max-width: 100%;
  }
  .header__rightitem.mobile-menu-hide {
    display: none;
  }
}

/* Header Top Nav */



@media(min-width:1101px) {
  .header__right {
    gap: $section-spacer;
  }
  .megamenu-mobile-toggle {
    display: none;
  }
}

@include media-breakpoint-up(xxl) {
  .header__right {
    gap: $spacer * 2;
  }
}

.header__right,
.header-nav__right,
.footer-bar__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include media-breakpoint-desktop {
    position: relative;
  }
}

.header-nav,
.footer-bar {
  background-color: $header-nav-bg-color;
  color: $header-nav-color;
  padding-top: $header-nav-padding-y;
  padding-bottom: $header-nav-padding-y;
  
  .btn {
    color: $header-nav-color;
  }
  .header-nav__wrapper {
    min-height: $spacer * 3;
  }
}

.header-nav__right,
.footer-bar__right {
  @include transition-fast;
  svg {
    @include transition(inherit);
  }
  a {
    @include transition(inherit);
  }

}

.header__logo--checkout {
  max-width: 200px;
}
.header-nav--checkout {
  padding-top: $spacer;
  padding-bottom: $spacer;
}
@include media-breakpoint-down(xl) {
  .megamenu-mobile-toggle {
    cursor: pointer;
    z-index: 999999;
    margin-right: $spacer;
    svg {
      line {
        transform-origin: center;
        @include transition-fast;
      }
      &.megamenu-open {
        line {
          &:nth-of-type(1) {
            opacity: 0;
          }
          &:nth-of-type(2) {
            transform: rotate(45deg) translateY(25%);
          }
          &:nth-of-type(3) {
            transform: rotate(-45deg) translateY(-25%);
          }
        }
      }
    }
    &:hover,
    &:active,
    &:focus {
      svg {
        color: $primary;
      }
    }
  }

  .header__right {
    gap: $spacer;
  }
}

.header-nav {
  background:$primary;
  .link-block {
    h3 {
      display: none;
    }

    ul {
      display:flex;
      li {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        margin-right: $spacer*2;
      }

    }
  }

  a {
    color:$white;
    font-size: $font-size-base*0.875;
    font-weight: $weight-medium;
  }

  .header-nav__right a {
    font-weight:$weight-light;
}
    
    .header-nav__right li:last-child {
      margin-right: 0}
}

.header-top__content {
  padding-top: $spacer;
  padding-bottom: $spacer;
  @include media-breakpoint-down(md) { 
    padding-top: $spacer/2;
    padding-bottom: $spacer/2;
  }
}

.header_contact_wrapper {
  display: grid;
  grid-template-columns: repeat(2,auto);
  gap:$section-spacer/2;
  @include media-breakpoint-down(md) {
    display: none
  };
  .header_icon_wrap {
    background: $gradient;
    border-radius: 100%;
    width: $spacer*2.75;
    height: $spacer*2.75;
    margin-right:$spacer/2;
    @media (max-width:$bp-mobile-sm) {
      width: $spacer*2.25;
      height: $spacer*2.25;
      margin-right:$spacer*.25;
    }
  }
  svg {
    fill: $white;
    padding: $spacer*0.65;
    width: $spacer*2.75;
    height: $spacer*2.75;
    @media (max-width:$bp-mobile-sm) {
      padding: $spacer*0.5;
      width: $spacer*2.25;
      height: $spacer*2.25;
    }
  }

  .header_contact_box {
    display: flex;}


  .header_contact_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:$spacer/2;
    font-size: $font-size-base*0.75;
    font-weight: $weight-regular;
    a {
        text-transform: uppercase;
        color:$off-black;
        font-size: $font-size-base*0.875;
        font-weight: $weight-medium;
    }
}
}




.menu_wrapper {
  background:$off-white;
  box-shadow: $header-shadow;
  font-size: $font-size-base*0.875;
  font-weight: $weight-medium;
  .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    

    @media (min-width: 1600px) {
      & {
        max-width:1428px
      }
    };}

    svg {
      width: $font-size-base*1.15;
      height: $font-size-base*1.15;
      margin-right: $spacer/4;
    }
  
    @include media-breakpoint-up(lg) {
      .search-widget{
        display: none;
      }
    }

}



.icons__menu {
  display: flex;
  align-items: stretch;
  >div:not(.visible--mobile) {
    padding: $spacer;
    border-right: solid $gray-200 1px;
    display: flex;
    align-items: center;
    &:first-child {
      border-left: solid $gray-200 1px;
    }
    @include media-breakpoint-down(md) { 
      border-right:none
    }

    @media (max-width:$bp-mobile) {
      padding: $spacer;
    }
  }
}

.cart-icon a {
  position:relative;
}

.header__logo {
  width:100%;
  max-width:$spacer*12
}


@media (min-width:1231px) and (max-width:1400px) {
  span.cart-text, span.user-info-text {
    display: none;
}

.megamenu .megamenu-wrapper ul.menu-content {
  column-gap: $spacer*1.25;}
}


@media (min-width:1101px) and (max-width:1230px) {
  span.cart-text, span.user-info-text {
    display: none;
}

.megamenu .megamenu-wrapper .menu-content .menu-item>a{
    padding: 1rem 0;}
  
.megamenu .megamenu-wrapper .menu-content .menu-item>a span{

  font-size: .8rem;}
  .megamenu .megamenu-wrapper ul.menu-content {
    column-gap: $spacer*1.25;}
  

    .icons__menu>div:not(.visible--mobile) {
      padding: 1rem 0.5rem;}

}