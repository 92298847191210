.megamenu-wrapper {
  .unvisible {
    display: none;
  }
  .headerSpace {
    display: none;
  }
  .img_desktop {
    overflow: hidden;
    display: block;
    position: relative;
  }
  img {
    width: 100%;
    @include transition(all 300ms linear);
    &:hover {
      transform: scale(1.05) rotate(0.05deg);
      @include transition(all 300ms linear);
    }
  }
}

.menu_wrapper {
  .home-btn  {
    margin-right: -$spacer;
    svg {
      width: $spacer;
      height: $spacer;
      margin-bottom: $spacer/5;
      margin-right: 0;
      fill:$primary;
      @media (max-width:$bp-desktop-lg-up) {
        display: none;
      }
    }
    span {
      @media (min-width:1101px) {
        display: none;
      }
    }
  }
}

.megamenu {
  
  .megamenu-wrapper {
    position: relative;
    .menu-content {
      display: flex;
      flex-flow: row wrap;
      column-gap: $spacer * 2;
      row-gap: $spacer / 2;
      width: 100%;
      .menu-item {
        display: block;
        > a {
          position: relative;
          padding: $spacer 0;
          font-size: $font-size-base * 0.875;
          font-weight: $weight-medium;
          text-transform: uppercase;
          color: $black;
          position: relative;
          cursor: pointer;
          @include transition-fast;
          // &:after {
          //   content: "";
          //   width: 100%;
          //   height: $spacer * 0.125;
          //   position: absolute;
          //   bottom: $spacer * 0.125;
          //   left: 0;
          //   background-color: $primary;
          //   transform: scaleX(0);
          //   transform-origin: left;
          //   @include transition(all 300ms ease);
          // }
        }
        &:hover,
        &.active,
        &.home {
          > a {
            border-color: $primary;
            color: $primary;
            &:after {
              transform: scaleX(1);
              opacity: 1;
            }
          }
        }

        @include media-breakpoint-mobile-menu(up) {
          &:hover {
            > .menu-dropdown {
              opacity: 1;
              pointer-events: all;
              transform: translate(-25%, 0) rotateX(0);
            }
          }
          .submenu-anchor-icon {
            display: inline;
            pointer-events: none;
          }
          .menu-dropdown {
            width: max-content;
            // max-width: calc(100vw - #{$spacer});
            max-width: Min(64rem, 100vw);
            width: max-content;
            position: absolute;
            top: 100%;
            z-index: 9;
            text-align: left;
            pointer-events: none;
            padding-top: $spacer * 2;
            opacity: 0;
            transform-origin: bottom center;
            transform: translate(-25%, $spacer) rotateX(-15deg);
            @include transition;
            .pos-sub-inner {
              box-shadow: $box-shadow-lg;
              background: $white;
              padding: $spacer * 2;
              border-radius: 0 0 $border-radius-lg $border-radius-lg;
            }
            .submenu-item {
              > a {
                color: $black;
                line-height: $line-height-base;
                text-transform: capitalize;
                font-size: $small-font-size;
                font-weight: $weight-bold;
                display: block;
              }
              .category-sub-menu {
                a {
                  color: $gray-500;
                  text-transform: capitalize;
                  line-height: $big-line-height;
                  font-weight: $weight-regular;
                  font-size: $small-font-size;
                  display: block;
                  @include transition;
                  &:hover {
                    color: $primary;
                    transform: translateX($spacer * 0.125);
                  }
                }
              }
            }
            .pos-menu-row {
              margin-bottom: $spacer * 2;
              margin-bottom: $spacer * 1.25;
              &:last-child {
                margin-bottom: 0;
              }
            }
            .ul-column {
              .submenu-item {
                &:last-child {
                  margin-bottom: 0;
                }
                margin-bottom: $spacer * 1.25;
              }
            }
            .category-sub-menu {
              margin-top: $spacer * 1.25;
            }
          }
        }

        .column_title {
          color: $black;
          line-height: 1;
          text-transform: capitalize;
          font-size: $small-font-size;
          font-weight: 700;
          display: block;
          margin-bottom: $spacer * 1.25;
        }
        .menu-subtitle {
          display: block;
          padding: 0 10px;
          font-size: 11px;
          background: $red;
          color: $white;
          font-weight: $weight-regular;
          line-height: $line-height-base;
          position: absolute;
          margin-top: -20px;
          right: 0;
          z-index: 10;
          @include border-radius(.125rem);
          &:after {
            content: "";
            top: 100%;
            left: 50%;
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border: solid transparent;
            border-top-color: $red;
            border-width: 4px;
            margin-left: -4px;
          }
        }
      }
    }
  }
  @media(min-width:1100px) {
    .icon-drop-mobile,
    .mobile-menu__links {
      display: none;
    }
    .collapse-disabled-desktop {
      display: block;
      height: auto !important;
      visibility: visible;
    }
  }
}

.menu-product {
  display: inline-block;
  width: 100%;
  a {
    padding: 0 !important;
    line-height: 18px !important;
    border: none !important;
  }
  .product_desc {
    overflow: hidden;
    padding: 10px 0 0 0 !important;
    .product_name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .product-price-and-shipping {
      position: static;
      box-shadow: none;
      padding: 0;
      height: auto;
      line-height: 1;
    }
    .hook-reviews {
      float: none !important;
      margin-bottom: 10px !important;
    }
  }
}

@media (max-width:1100px) {
  .megamenu {
    position: absolute;
    z-index: 99999;
    width: 100%;
    left: 0;
    top: 100%;
    margin-top: $spacer/4;
    background: $white;
    border-radius: $border-radius-lg;
    pointer-events: all;
    box-shadow: $box-shadow-lg;
    @include transition;
    &.megamenu-hidden {
      pointer-events: none;
      opacity: 0;
      transform: translate(0, -$spacer/2) rotateX(-15deg);
    }
    .megamenu-wrapper {
      .menu-content {
        max-height: calc(100vh - #{($header-height + $spacer + ($header-padding-y * 2))});
        padding: $spacer;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        overflow-x: hidden;
        overflow-y: auto;
        .menu-item {
          > a,
          .submenu-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            @include transition-fast;
            &:hover {
              color: $primary;
            }
          }
          .submenu-anchor-icon {
            flex-grow: 1;
            text-align: right;
            svg {
              @include transition;
            }
            &:not(.collapsed) {
              svg {
                transform: rotate(-180deg);
              }
            }
          }
        }
      }
      .menu-dropdown {
        .pos-sub-inner {
          padding: 0 ($spacer * 0.25);
          padding-top: ($spacer * 0.5);
        }
        .submenu-title {
          color: $text-black;
          line-height: $big-line-height;
          font-weight: $weight-medium;
          font-size: $h5-font-size;
          display: block;
          padding-top: $spacer * 0.5;
          padding-bottom: $spacer * 0.25;
          @include transition;
        }
        li {
          line-height: $line-height-base * 1.5;
          > a {
            color: $text-gray;
          }
        }
        .submenu-item {
          li {
            line-height: $line-height-base * 1.5;
            > a {
              display: block;
              @include transition-fast;
              &:hover {
                color: $primary;
                transform: translateX($spacer * .125);
              }
            }
          }  
        }
      }
    }

    .icon-drop-mobile {
      flex-grow: 1;
      text-align: right;
      svg line:first-child {
        stroke-dasharray: 24;
        stroke-dashoffset: 24;
        @include transition-fast;
      }
      &.collapsed svg line:first-child {
        stroke-dashoffset: 0;
      }
    }
    
  }
  .mobile-menu__links {
    width: 100%;
    a {
      color:$primary;
      display: block;
      padding: $spacer;
      background-color: $gray-100;
      @include transition-fast;
      &:hover,
      &:active,
      &:focus {
        background-color: $gray-200;
      }
      svg {
        margin-right: $spacer/4;
      }
      svg, path {
        fill:$primary
      }
    }
  }
}
