.block_newsletter {
    background-color: $off-white;
    h2 {
        font-size: $display3-size;
    }
    .newsletter-icon {
        width: 4rem;
        height: 4rem;
        color: $primary;
        margin-bottom: 1rem;
    }
    @include media-breakpoint-desktop {
        input[type=email] {
            min-width: 32rem;
        }
    }

}