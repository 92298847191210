.autocomplete-suggestions {
    text-align: left;
    cursor: default;
    // border: 1px solid $dark;
    border-top: 0;
    background: $white;
    margin-top: $spacer;
    box-shadow: $box-shadow-lift-up;
    @include border-radius($border-radius-lg);
    @include scrollbar;

    /* core styles should not be changed */
    position: absolute;
    display: none;
    z-index: 9999;
    max-height: 254px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}
.autocomplete-suggestion {

    text-overflow: ellipsis;
    font-size: small;
    padding: $spacer $spacer*.75;
    // border-top: 1px solid $dark;
    cursor: pointer;
    @include transition-fast;

    .media-body {
        font-weight: $weight-medium;
    }
    &:hover {
        background-color: $gray-200;
    }
}
.autocomplete-suggestion img{

    max-width: $size-base;
    max-height: $size-base;

}
.autocomplete-suggestion:first-child {
	border-top: none;
}
// .autocomplete-suggestion.selected {
//     background: $dark;
// }
