.account-links{
  align-items: stretch;
  > a{
    margin-bottom: $spacer;
  }
  .link-item{
    box-shadow:$card-box-shadow;;
    padding: $spacer;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    height: 100%;
    i{
      margin: 0 auto $spacer;
      font-size: $font-size-base*3;

    }
  }
}

.address-header{
  background-color: transparent;
}

.address-footer{
  display: flex;
  justify-content: space-between;

}

.form--100{
  min-width: 100%;
  width: 100%;
}

#company_info {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $spacer;
  overflow: hidden;
  margin: 0;
  &:not(.open) {
    max-height: 0;
    opacity: 0;
    transition: max-height 1.5s cubic-bezier(0, 1, 0, 1), opacity 0.75s cubic-bezier(0, 1, 0, 1);
  }

  &.open {
    max-height: auto;
    opacity:1;
    transition: max-height 1s ease-in-out, opacity 0.5s ease-in-out;

  }
}





#customer-form {
  section {
  display: flex;
  gap:$spacer;
  flex-wrap: wrap;

    > .form-group {
      width: 100%;
      &:not(.full) {
        width: calc(50% - 0.5rem);
        box-sizing: border-box;
      }
    }
  }
}

#authentication, #password {
  #content-wrapper {
    max-width: $spacer*65;
    margin: 0 auto;
    margin-top: $spacer*3;
    border-radius: $border-radius-lg;
    padding:$section-spacer/2;
    @include box-shadow-sm;
  }

  .page-header {
    border:none;
    padding: 0;
    h1 {
      font-weight: $weight-medium;
    }
  }

  .custom-control:not(.custom-control-inline) {
    padding-left: 0;
  }
}

input[type=checkbox] {
  visibility: hidden;
}

/* Creating a custom checkbox
based on demand */
.checkmark {
  position: absolute;
  top: -5px;
  left: -$spacer*2;
  height: $spacer*1.5;
  width: $spacer*1.5;
  background-color: $white;
  border:solid 1px $primary
}

/* Specify the background color to be
shown when hovering over checkbox */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox{
  label {
    position:relative;
    left:$spacer*2
  }

  &:hover input ~ .checkmark {
    background:$white;
    border:solid 1px $primary
  }

  input:active ~ .checkmark {
    background: $primary
  }

  input:checked ~ .checkmark {
    background-color: $white;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 7px;
    bottom: 5px;
    width: 9px;
    height: 18px;
    border: solid $primary;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

#password {
  #content-wrapper {
    max-width: $spacer*35;}
  }

  .card-footer, .page-footer {
    border:none
  }