img {
    max-width: 100%;
}

@media (min-width: 1600px) {
    .container {
      max-width:1428px
    }
}
    // Button
.layout-section {
    padding-top: $section-spacer;
    padding-bottom: $section-spacer;
    &#wrapper {
        padding-top: $spacer*3;
        padding-bottom: $spacer*3;
    }
}

.btn {
    padding: ($spacer * 1.25) ($spacer * 2);
    position: relative;
    overflow: hidden;
    z-index: 10;
    border: none;
    box-shadow: none !important;
    @include border-radius($btn-radius);
    font-size: $btn-font-size;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: $spacer * 0.25;
    @include transition-fast;
}

.btn-primary {
    @include button-variant(transparent, transparent, transparent, transparent);
    @include btn-hover;
    svg {
        width: $btn-icon-size;
        height: $btn-icon-size;
        margin-left: $spacer*0.75;
        margin-right: -$spacer*0.75;
        min-width: $btn-icon-size;
    }
}

.btn-outline-primary {
    @include button-outline-variant(
        transparent,
        transparent,
        transparent,
        transparent
    );
    color: $text-black;
    @include btn-hover-outline;
    svg {
        width: $btn-icon-size;
        height: $btn-icon-size;
        opacity: $btn-icon-opacity;
    }
}

.card {
    border: none;
}

.card-body {
    position: relative;
    @include media-breakpoint-mobile(up) {
        padding: ($spacer * 1.5) ($spacer * 1.5);
    }
    @include media-breakpoint-mobile() {
        padding: ($spacer * 1.5) ($spacer * 1.5);
    }
}



// Form

.form-control, .custom-select, .custom-file input, .custom-file-label {
//     &[type="email"],
//     &[type="text"],
//     &[type="password"],
//     &[type="tel"]  {
        min-height: $size-base;
        @include border-radius($border-radius-lg);
        border-color: lighten($primary, 25%);
        &:focus,
        &:active {
            border-color: $primary;
        }
    
}

#contact {
    input, select, textarea, .custom-file-label {
        border-color: $gray-300;
    }
    textarea {
    min-height: $section-spacer;
    padding: $spacer;}

    .icon {
        width: $font-size-base*1.75;
        height: $font-size-base*1.75;
        margin:$spacer*1.25 0 $spacer/4;
        i{
            font-size: $font-size-base*1.75;
            color:$primary
        }
    }
    .contact-form {
        padding: $spacer*2.5;
        box-shadow: $box-shadow-sm;
        border-radius: $border-radius-lg
        
    }
    .contact-rich a {
        display: block;
        margin-top: $spacer/4;
        font-weight: $weight-medium-regular;
    }
}
.custom-select {
    min-height: $size-base;
}

.custom-file {
    min-height: $size-base;
    .custom-file-label {
        height: 100%;
        &:after {
            display: flex;
            align-items: center;
            height: 100%;
            background-color: $primary;
            color: $white;
        }
    }
}

.input-group-btn {
    button {
        height: 100%;
    }
}

form label {
    font-weight: $weight-bold;
}

.custom-control-label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.custom-checkbox .custom-control-label:before {
    @include border-radius(50%);
}

.form-control.is-valid,
.was-validated .form-control:valid {
    background-position: right ($spacer * 1) center;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiMxMGM1NTAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItY2hlY2siPjxwb2x5bGluZSBwb2ludHM9IjIwIDYgOSAxNyA0IDEyIj48L3BvbHlsaW5lPjwvc3ZnPg==");
}

.custom-control-label {
    color: $gray-500;
}

.card-header,
.page-header {
    background-color: $white;
}
.card-footer,
.page-footer {
    background-color: $white;
}

.input-group:not(.has-validation) > .input-group-btn:last-of-type button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.breadcrumb-item + .breadcrumb-item {
    padding-left: $spacer*1.5;
    &::before {
    content: "";
    background: url("/themes/norman-theme/assets/img/sipka.svg") no-repeat;
    position: relative;
    width: $spacer*1.25;
    height: $spacer*1.25;
    right:$spacer*0.75;
    }
}

/* other */

.colored {
    color:$primary
}

a.download {
    position: relative;
    font-weight: $weight-medium-regular;
    margin-left: $spacer*1.5;
    border: solid 1px $primary;
    padding: $spacer*0.75 $spacer*1.25  $spacer*0.75  $spacer*2.25;
    border-radius: $btn-radius;
    
    &::before {
        content: "";
        position: absolute;
        width: $spacer;
        height: $spacer;
        background: url(/img/cms/download.png) no-repeat;
        left: $spacer*.85;
        top: $spacer*.85;
        background-size: contain;
    }
    &:hover {
        color:$white;
        background-color: $primary;
        &::before {
            filter:brightness(25) grayscale(1);
        }
    }
}

/* bredcrumbs */

.breadcrumb-wrapper {
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $spacer;
    border-bottom: solid 1px $gray-200;
}

.breadcrumb {
    font-size:$font-size-base*.875;

    svg {
        fill: $primary;
        margin-right: $spacer/2;
        bottom: -1px;
        position: relative;
        }

    a {
        color: $gray-900;
    }

    .breadcrumb-item.active span{
        font-weight: $weight-medium;
        position:relative;
        &::before {
            content: "";
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            background: $gray-900
        }
    }
}

#content-wrapper {
    margin-top: $spacer*3;
}

.hi-faq-main-page, .hi-faq-category-page {
    .hi-faq-category-item h2 a {
        color:$gray-900
    }

    .hi-faq-item {
        border-color:$gray-200;
        border-radius: $border-radius-md;
        box-shadow:$header-shadow;
      }
}



#certifikace a {
    display: block;
    font-weight: $weight-medium-regular;
} 


.map_section {
    display: grid;
    gap: $spacer*3;
    grid-template-columns: 70% 1fr;
    margin-bottom: $spacer*3;
    path:not(.area) {
        fill: $gray-800;
    }

    .area {
        fill: white;
        @include transition-fast;
        &:hover, &.colored {
            fill: $primary;
            cursor: pointer;
        }
    }

    .btns_wrap {
        display: flex;
        flex-direction: column;
        gap: $spacer/2;
        &.stucked {
            height: calc(100vH - 11rem);
            .btn {
                height: 100%;
            }}
        .btn {
            padding: $spacer*0.65 ;
            text-transform: uppercase;
            @include transition-fast;
            &::after {
                    background: $secondary;
            }
        }
    }

    .map_wrapper {
        position:relative;
        .map_tooltip {
            position: absolute;
            right: $spacer*10;
            top: $spacer*6;
            padding: $spacer;
            box-shadow: $box-shadow;
            background: white;;
            border-radius: $border-radius-md;
        }
    }

    
}

.store_wrapper h2{
        font-weight: $weight-medium;
        font-size: $font-size-base*1.5;
        text-transform: uppercase;
}

.store_box_wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $spacer*2;
    margin: $spacer*2 0 $section-spacer;
    .store_box {
        box-shadow:$box-shadow;
        border-radius: $border-radius-md;
        padding: $spacer*1.75;
        a, strong {
            display:block;

        }

        strong {
            font-size: $spacer*1.25;
            margin-bottom: $spacer/4;
        }
        a {
            font-weight: $weight-medium-regular;
            margin: $spacer/2 0;
            color:$gray-900;
            &:hover{
                color:$primary;
            }
            &[href^="tel:"]::before, &[href^="mailto:"]::before, &.weblink::before, &.maplink::before {
                content: "";
                background: url(/themes/norman-theme/assets/img/phone.svg) no-repeat;
                width: 0.85rem;
                height: 0.85rem;
                position: relative;
                display: inline-block;
                margin-right: 0.5rem;
                bottom: -2px;
            }
            &[href^="mailto:"]::before {
                background: url(/themes/norman-theme/assets/img/email.svg) no-repeat;
            }

            &.weblink::before {
                background: url(/themes/norman-theme/assets/img/web.svg) no-repeat;
            }

            &.maplink::before {
                background: url(/themes/norman-theme/assets/img/map.svg) no-repeat;
            }
        }


        
    }
}

#prodejci {
    padding-top: $section-spacer;
    padding-bottom: $section-spacer;}

    /* gallery */ 

    ul#gallery_container  li a {
        border: none;
    }

    .gallery_1 {
        display: flex;
        flex-wrap: wrap;}

    .gallery_2 {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        @media(max-width:$bp-tablet) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media(max-width:$bp-mobile) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width:$bp-mobile-xs) {
            grid-template-columns: repeat(1, 1fr);
        }
        .label-image {
            margin-top: $spacer/2;
        }
        img {
            object-fit: cover;
        }
        }
/* o nas */
.o-nas-info {
    display: flex;
    >div {
        flex-basis: 50%;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        margin-bottom: $spacer
    }
    a {
        color:inherit
    }

    strong {
        display: block;
    }
}

/* info banner */


.top-info-banner {
    position: absolute;
    width: 100%;
    background: $secondary;
    padding: $spacer;
    z-index: 999;
    color: $white;
    display:none;
    text-align: center;
    p {
        margin: 0;
        font-size: $font-size-base*.875;
    }

    .close-btn {
        max-width: $spacer*.75;
        position: absolute;
        right: $spacer/2;
        top: $spacer/2;
        fill: $white;
        cursor:pointer;
    }
}
/* gdpr*/
#exportDataToCsv {
    margin-bottom: $spacer/2;
}
@media (max-width:$bp-mobile-lg) {
    .psgdprinfo17 {
        flex-direction: column;
        align-items: flex-start;
    }

    a.psgdprgetdatabtn17 {
        float: left;
    }
    
}


/* accessories */
.accessories-nav {
    display: flex;
    flex-wrap: wrap;
    gap: $spacer/2;
    padding-top:  $spacer;
    padding-bottom: $spacer*3;
  }
