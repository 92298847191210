.footer-banner-section {
  overflow: hidden;
  position:relative;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: transparentize($black, 0.45);
    z-index: 1;
  }
  
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    
  }

  >div {
    position:relative;
    z-index: 2;
  }
  
  .top-title {
    color:$primary;
    font-weight: $weight-bold
  }

  h2 {
    font-size: $font-size-base*3.125;
    color:$white;
    font-weight: $weight-bold;
    @media (max-width:$bp-tablet) {
      font-size: $font-size-base*2;
    }
  }

  p {
    color: $white;
    max-width: $spacer*41;
    line-height: 1.8em;
  }
  .btn {
    font-size:$font-size-base;
    margin-top: $spacer/2;
  }

}



.l-footer {
  background-color: $footer-bg;

  .cms-page-link,
  .account-list a {
    color: $text-muted;
    &:hover {
      color: $primary;
    }
  }
}
.footer-container {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap:$spacer;
  padding-top: $spacer*4;
  padding-bottom: $spacer*4;
  @media (max-width:$bp-tablet) {
    grid-template-columns: repeat(2,1fr);
  }
  @media (max-width:$bp-mobile) {
    grid-template-columns: repeat(1,1fr);
  }
  >div {
    padding-top: $section-spacer/2;
    padding-bottom: $section-spacer/2;
  }
}
.footer-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: $spacer;
  margin-bottom: $spacer * 8;
}

footer .link-block {
  li {
    margin-bottom: $spacer * 0.75;
  }
  a {
    font-size: $font-size-base*0.875;
    color: $gray-400;
    margin-left: $spacer*0.75;
    position:relative;
    @include transition-fast;
    &::before {
      content: "";
      width: $spacer/4;
      height:1px;
      position: absolute;
      left: -$spacer*0.65;
      background: $gray-400;
      bottom: $spacer/2;
      @include transition-fast;
      transition-delay:  .1s
      
    }

    &:hover {
      margin-left: $spacer*1;
      &::before {
        width:$spacer*0.5;
        left:-$spacer*0.75;

      }
    }
  }
}
.block-contact {
    padding-left: $section-spacer;
    border-left: solid 1px $gray-100;
  @media (max-width:$bp-tablet){
    border:none
  }}


.block-contact .footer-contact-block {
  > a {
    color:$gray-500;
    font-size: $font-size-base*0.875;
    font-weight: $weight-medium;
    @include transition-fast;
    &:hover,
    &:active,
    &:focus {
      color: $primary;
    }
  }
  gap: $spacer * 0.5;
  margin-bottom: $spacer;
}

.footer__title {
  color: $gray-800;
  font-size: $font-size-base;
  text-transform: uppercase;
  &:visited,
  &:hover {
    color: inherit;
  }
  display: block;
  font-weight: $weight-medium;
  margin-bottom: $spacer;
  &--mobile {
    display: none;
    margin-bottom: $spacer;
    padding: $spacer/2 0;
    border-bottom: 1px solid $border-color;

    &:after {
      content: "\e313";
      font-family: "Material Icons";
      font-feature-settings: "liga" 1;
      position: absolute;
      right: $grid-gutter-width/2;
      @include transition(0.3s transform ease-in-out);
      font-size: $font-size-base * 1.5;
      font-weight: 100;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform: rotate(180deg);
    }
  }
}

.footer-bar {
  gap:$spacer;
  background-color: $off-black;
  color: $gray-300;
  padding: $spacer;
  font-size: $font-size-base*0.875;
  a {
    color:$gray-300;
    &:hover {
      text-decoration: underline;
    }
  }
  >span {position:relative}
  >span:not(:last-child)::before {
    content: "";
    width: 1px;
    height: 100%;
    background-color: $gray-300;
    position: absolute;
    right: -$spacer/2;
  }
}

.header__nav {
  align-items: center;
}
.header-nav__nav2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top {
  display: flex;
  justify-content: space-between;
  position: static;
}

@include media-breakpoint-mobile {
  .footer-row {
    .footer-logo,
    .link-block {
      flex-basis: 100%;
    }
    .block-contact {
      order: 1;
    }
  }
  // .footer__title {
  //   &--desktop {
  //     display: none;
  //   }
  //   &--mobile {
  //     display: block;
  //   }
  // }

}


.footer-logo img {
  max-width: $spacer*12;
}

.footer-social-icon svg {
  width: $spacer*2.5;
  height: $spacer*2.5;
  fill: $primary;
  box-shadow: 2px 3px 6px #33333342;
  padding: $spacer*0.55;
  border-radius: 100%;
}