/* product page edit */
#product {

    .product_reference {
        font-size: $font-size-base*.875;
        font-weight: $weight-medium;
        text-transform: uppercase;
    }

    h1 {
        font-size: $font-size-base*2;
        font-weight: $weight-medium;
        color:$primary;
        margin-top: $spacer/4;
    }

    .product-description a {
        display: block;
        margin-bottom: $spacer/2;
        font-weight: $weight-medium;
    }

    .product-description-short {
        font-size: $font-size-base*.875;
        line-height: 1.6em;
    }


    .alert-availability {
        margin-bottom: $spacer;
        display: block;
        text-transform: lowercase;
        small {
            display: block;
            margin-top: $spacer/2;
            color: $gray-700;
        }
    }

    #product-availability {
        text-align: right;
        display: none;
    }



    .product-flags {
        font-size:$font-size-base*.75;
    }

    .product-flag {
        padding: $spacer*.4 $spacer;
        text-transform: lowercase;
        border-radius: $btn-radius;}



}


