.has-dropdown {
  position: relative;
}

.has-dropdown:hover,
.has-dropdown.hovered {
    .dropdown-content-wrapper {
      transform: translate(0, $spacer *2) rotateX(0);
      transform-style: preserve-3d;
      opacity: 1;
      pointer-events: all;
    }
  }


  .dropdown-content-wrapper {
    max-width: $dropdown-default-width;
    padding-top: $spacer*2;
    pointer-events: none;
    position: absolute;
    right: 0;
    top:0;
    z-index: 20;

    opacity: 0;
    transform-origin: bottom center;
    transform: translate(0, -$spacer/2) rotateX(-15deg);
    @include transition(.3s ease);
    .dropdown-content {
        background: $white;
        box-shadow: $box-shadow-md;
        padding: $spacer*1.75;
        border-radius: $border-radius-md;
        &:not(.logged-in) {
          min-width: $dropdown-min-width;
        }
      }
  
}

