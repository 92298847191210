.prestathemeslider {
  .slider-content {
    min-height: 60vh;
  }
  .slider-content-item {
    position: absolute;
    overflow: hidden;
    padding-bottom: $spacer * 4;
    padding-right: inherit;
    transform-origin: left center;
    max-height: 100%;

    .slide-title {
      position: relative;
      font-size: $display1-size;
      font-weight: $display1-weight;
      @include transition(0.3s ease);
      .word {
        .text-wrapper {
          position: relative;
          display: inline-block;
          padding-top: 0.2em;
          padding-right: 0.05em;
          padding-bottom: 0.1em;
          overflow: hidden;
        }
        display: inline-block;
        white-space: break-spaces;
      }

      .letter {
        display: inline-block;
        line-height: 1em;
        transform-origin: 0 0;
      }
    }
    .slide-description {
      font-size: $h2-font-size;
      font-weight: $weight-regular;
      line-height: $big-line-height;
    }

    .slide-description,
    .slide-button {
      @include transition(0.6s ease-in-out);
      transition-delay: 0.15s;
    }

    .slide-button {
      transition-delay: 0.3s;
      font-size: $display5-size;
      font-weight: $weight-regular;
    }

    &:not(.active) {
      // opacity: 0;
      pointer-events: none;
      .slide-title {
        opacity: 0;
        transform: translate(-$spacer, 0) rotateX(-15deg);
      }
      .slide-description,
      .slide-button {
        transform: translate(-$spacer, 0) rotateX(-15deg);
        opacity: 0;
        transition-duration: 0.3s;
        transition-delay: 0;
      }
    }
  }

  .carousel-wrapper {
    gap: $spacer * 2.5;
  }

  .prestathemeslider-carousel {
    height: 60vh;
    max-width: 100%;
    overflow: hidden;
    @include border-radius(0.5rem);
    .carousel-image {
      height: 60vh;
    }
    .slick-slide {
      border: none;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .carousel-navigation ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $spacer * 0.5;
    li {
      line-height: 0;
      button {
        font-size: 0;
        line-height: 0;
        display: block;
        color: transparent;
        border: 0;
        cursor: initial;
        background: transparent;
        position: relative;
        display: inline-block;
        overflow: hidden;
        @include border-radius(4rem);
        width: 1rem;
        height: 100%;
        min-height: 1rem;
        background-color: $gray-200;
        @include transition(0.3s ease-in-out);
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          @include border-radius(4rem);
          left: 0;
          top: 0;
          transform: scale(0.25);
          @include transition(0.15s ease);
          opacity: 0;
          background-color: $primary;
        }
      }
      &.slick-active button {
        background-color: $primary;
        min-height: 3rem;
        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
      &:not(.slick-active) {
        &:hover {
          button:after {
            transform: scale(0.5);
            opacity: 1;
          }
        }
        button {
          cursor: pointer;
          &:focus:after {
            transform: scale(0.75);
            opacity: 1;
          }
          &:active:after {
            background-color: $primary;
            transform: scale(1);
            opacity: 1;
          }
        }
      }
    }
  }
}

@include media-breakpoint-tablet {
  .prestathemeslider {
    .carousel-wrapper {
      flex-direction: column;
      .carousel-navigation ul {
        flex-direction: row;
        li {
          button {
            height: 1rem;
            width: 100%;
            min-width: 1rem;
          }
          &.slick-active button {
            min-width: 3rem;
            min-height: 1rem;
          }
        }
      }
    }
  }
}

@include media-breakpoint-only(lg) {
  .prestathemeslider {
    .carousel-wrapper {
      padding-right: 0;
      .carousel-navigation {
        position: absolute;
        right: $spacer;
      }
    }
  }
}

@include media-breakpoint-tablet {
  .prestathemeslider {
    .carousel-wrapper {
      .carousel-navigation {
        position: absolute;
        bottom: $spacer;
      }
    }
  }
}