//option
$enable-fluid-layout:            true;

/* Defaults */
$size-base:                     $spacer * 3.125;


/* HEADER */
$header-bg:                     $white;
$header-height:                 $size-base;
$header-padding-y:              $spacer * 2.5;
$header-padding-y-mobile:       $spacer * 1.25;
$header-nav-bg-color:           $gray-100;
$header-nav-color:              $body-color;
$header-nav-padding-y:          null;
$header-nav-border-color:       $border-color;
$header-top-border-color:       $header-nav-border-color;
$header-nav-right-bg-color: 	$gray-200;

// Page full height
$full-height:                   calc(100vh - #{$header-height + ($header-padding-y-mobile * 2)} + 1px);

/* MAIN */
$spacer-y : $spacer*2;
$wrapper-bg:                    $white;

/* FOOTER */
$footer-bg:                     $white;
$footer-container-margin-top:   $spacer;


/* Button Settings */

$btn-bg:                        $off-black;
$btn-icon-size:                 $icon-normal-size;
$btn-font-size:                 $small-font-size;
$btn-radius:                    $border-radius-lg;
$btn-active-bg:                 $primary;
$btn-icon-opacity:              .75;

/* USP Settings */

$usp-icon-size:                 $icon-2xl-size;
$usp-icon-stroke-width:         1;
$usp-icon-color:                $primary;
$usp-title-font-size:           $h3-font-size;
$usp-title-font-weight:         $weight-medium;
