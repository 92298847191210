.block-categories {
  box-shadow:$card-box-shadow;;
}





.featured-categories {
  margin-bottom: $spacer * 4;
  .row {
    row-gap: $spacer * 2.5;
  }
  .category-item > .card {
    @include transition-fast;
    a {
      @include transition-fast;
    }
    &:hover {
      box-shadow: $box-shadow-lift-up;
      transform: translateY(-0.25rem);
      a {
        color: $primary !important
      }
    }
  }
  .category-thumbnail {
    img {
      width: 100%;
      height: 100%;
      max-height: 20rem;
      padding: 0 ($spacer * 1.5);
      object-fit: scale-down;
    }
  }
  .category-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .category-title {
      color: $text-black;
      font-size: $display4-size;
      font-weight: $weight-medium;
      a {
        color: inherit;
      }
    }
    .view-more a {
      font-size: $h3-font-size;
    }
  }
}


/* typove sestavy */

.category-menu.imagelist {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax($spacer*25, auto);
  gap: $spacer*3;
  a {
    width: 100%;
    padding: 0;
    flex-wrap: wrap;
    overflow: hidden;
    &:hover {
      background: none;
      color:inherit;
      h2 {
        color:$primary;
      }
    }
  }
  img {
    width: 100%;
    height: $spacer*18;
    object-fit: cover;
  }
  

  h2 {
    font-size: $font-size-base*1.25;
    font-weight: $weight-medium-regular;
    padding: $spacer*1.25;
    text-align: center;
    text-transform: none;
  }

  
}