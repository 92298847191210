.main-title {
  font-size: $font-size-base*2;
  font-weight: $weight-medium;
  text-transform: uppercase;
  margin-bottom: $spacer*2
}

.layout-home {

  .featured-products {
    background-color: $gray-200;
  }
  section#main {
      background-color: $white;
      min-height: 622px;
  }
  main {
      background-color: $off-white;
      display: grid;
  }
    .view-more {
      a {
        font-size: $display5-size;
      }
      svg {
        width: $icon-large-size;
        }
    }

    .cta-section {
      background-color: white;
      h2 {
        font-size: $display2-size;
        font-weight: $weight-medium;
        margin-bottom: $spacer * .75;
      }
      .description {
        font-size: $h2-font-size;
        font-weight: $weight-regular;
        line-height: $big-line-height;
      }
      &.alternate .gradient {
        background: linear-gradient(90deg, rgba(241,243,246,1) 0%, rgba(252,243,241,1) 100%);
      }
    }
}

div.homeslider-container {
  margin-bottom:$spacer*0.25;
  min-height: $spacer*40;
  background: $white;
  ul, li, li >a, img {
    min-height: $spacer*40;
    max-height: unset;
    top: 0;
  }
  img {
    object-fit: cover;
  }
}

.homeslider {
  

  li.slide {

    overflow:hidden;
    > a {
      position:relative
    }



    .caption {
      max-width: 55%;
      height: 100%;
      display:flex;
      flex-direction: column;
      justify-content: center;
      padding: $spacer*8;
      font-size: $font-size-base*1.25;
      font-weight: $weight-medium;
      line-height: 1.6;
      padding-left: calc((100% - 1390px)/2);
      padding-right: calc((100% - 1390px)/2);
      @include blured;
      @media (max-width:$bp-desktop-lg) {
        padding:$spacer*8 $spacer*5;
      }

      @media (max-width:$bp-tablet) {
        max-width: 75%;
      }
      @media (max-width:$bp-mobile) {
        max-width: 100%;
        padding-left:$spacer*1.75;
      }
    }

    .btn {
      font-size: $font-size-base;
      padding: $spacer $spacer*2;

    }
  }

  h2 {
    font-size:$font-size-base*3.75;
    font-weight: $weight-bold;
    @media (max-width:$bp-desktop-sm) {
      font-size:$font-size-base*2;
    }
  }

}


a.homeslider_nav {
  opacity: 1;
  background: white url(/themes/norman-theme/assets/img/arrow-left.svg) no-repeat;
  background-size: 35%;
  left:$spacer;
  background-position: center center;
  width: $font-size-base*3;
  height: $font-size-base*3;
  border-radius: 100%;
  @include box-shadow-sm;
  &.next {
    background: white url(/themes/norman-theme/assets/img/arrow-right.svg) no-repeat center center;
    background-size: 35%;
    right:$spacer;
  }

  @media(max-width: $bp-mobile) {
    display: none;
  }
}


.dots {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: -$spacer*1.25;
}
.dot {
  width: $spacer*0.75;
  height: $spacer*0.75;
  background: $gray-300;
  border-radius: 100%;

  &.active {
    background: $primary;
    transform: scale(1.3);
  }
}


.about-section {
  background:$main-bg;
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 50%;
    background: linear-gradient(180deg, #fff, transparent);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  h2.main-title {
    margin-top: $spacer*6
  }
} 
.about_section_content_wrapper {
  position:relative;
  display: flex;
  gap: $section-spacer/2;
  align-items: center;
  line-height: 1.8;
  &>div, &>img {
    width:50%
  }

  p {
    margin-bottom: $spacer*2
  }

  .btn {
    text-transform: uppercase;
    font-size: $font-size-base*0.875;}
  .main-title {
    margin-top: $spacer*2
  }
}


.why-us-section {
  background: $gray-100;
}


.benefits {
  display: flex;
  justify-content: space-between;
  gap: $spacer;
  padding-top: $section-spacer/2;
  >div {
    text-align: center;
  }
  img {
    width:$spacer*2.5;
    height:$spacer*2.5 
  }

  h3 {
    font-size: $font-size-base;
    margin: $spacer 0;
    font-weight: $weight-medium;
}
  p {
    font-size: $font-size-base*0.875;
    max-width:$spacer*10
  }
}

.about-cta-section {
  background: $white;


  .main-title {
    max-width: $spacer*30;
  }
}

.about-bottom-section {
  background: $main-bg;
  background-size: contain;
  .main-title {
    max-width: $spacer*25;}
}

.about_section_boxes {
  display:flex;
  gap: $spacer*2;
  padding-top:$section-spacer;
  img {
    width: $spacer*3.75;
    height: $spacer*3.75;
  }

  >div {
    flex:1;
    background: $white;
    box-shadow: $card-box-shadow;
    padding: $spacer*2;
    border-radius: $border-radius-md;
  }

  h3 {
    font-size: $font-size-base*1.625;
    font-weight: $weight-medium;
    margin: $spacer*1.75 0 $spacer*1.25
  }

  p{
    line-height: 2em;
  }
}

