.product-line-actions{
  display:flex;
  justify-content: space-between;
  align-items: center;
  // .input-group.bootstrap-touchspin{
  //   max-width: 130px;
  // }

}
.product-line-grid{
  .attributes {
    flex-direction: column;
  }
  .product-line__title{
    margin-bottom: $spacer*.75;
    display: inline-block;
    color: $body-color;
  }
  .product-price{
    display: flex;
    align-items: center;
    font-weight: $weight-medium-regular;
    .discount{
      font-size: $font-size-sm;
      line-height: 1;
    }
  }
  .product-discount{
    margin-right: $spacer/2;
  }

}
.product-line__img{
  margin-right: $spacer;
}
@include media-breakpoint-mobile {
  .product-line__img{
    width: 100px;
    height: auto;
  }
  .product-line-actions {
  justify-content: flex-end;
    margin-top: $spacer;
    .product-price{
      margin:0 $spacer*2;
    }
  }
}
@include media-breakpoint-mobile() {
  .product-line__img{
    width: $size-base;
  }
  .product-line-actions {
    .product-price{
      margin:0 $spacer;
    }
  }

}

.cart__card-body{
  position: relative;
 .cart__card-loader{
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   align-items: center;
   justify-content: center;
   display: none;
   background-color: rgba($white, .85);
   opacity: 0;
   @include transition($transition-fade);
   z-index: 3;
 }
  &.is--loading{
    .cart__card-loader {
      display: flex;
      opacity: 1;
    }

  }
}
.nav-tabs--center{
  justify-content: flex-start;
    border: none;
    gap: $spacer;
}

.checkout-process {
  .card-header {
    padding: $spacer * 1.25;
    &:last-of-type {
      border:none
    }
  }
}

@include media-breakpoint-mobile{
  .checkout {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 1rem;
    background-color: rgba(0,0,0,.4);
    a{
      width: 100%;
    }
  }
}
.product-line__body{
  padding-right: $spacer/4;
}


  #checkout-login-form, #content-checkout-addresses-step {
    .form-fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $spacer;
    }
  } 

//order confirmation table
.total-value strong{
  font-weight: 700;
}
.table__title-head{
  margin-bottom: 0;
  font-size: $font-size-base;

}
/* CART */
.promo-code{
  margin-left: -$card-spacer-x;
  margin-right: -$card-spacer-x;
}
.promo-code-alert {
  display: none;
}

.card-body .separator{
  margin-left: -$card-spacer-x;
  margin-right: -$card-spacer-x;
}

.cart-grid-right .cart-summary {
  position: sticky;
  top: $spacer * 10;
}

.cart-summary-line{
  display: flex;
  justify-content: space-between;

}
.cart-total{
font-weight:$weight-regular;
}
.card-body--summary{
.cart-total{
  @extend .total-value;
  .label{
    margin-bottom: 0;
  }
}
  &.card-body{
    padding-bottom: 0;
  }
}
.media-list__item:not(:last-child){
  margin-bottom: $spacer/2;

}
.media-list__item {
  .product-price {
    font-weight: $weight-medium;
    color: $gray-900
  }
}

//cart summary
.link__showsummary{
  .material-icons{
    @extend .icon-collapse;
  }
  &[aria-expanded=true] .material-icons{
    transform: rotateZ(180deg);

  }
}
.cart-item:not(:last-child){
  border-bottom: 1px solid $border-color;
  margin-bottom: $spacer;
  padding-bottom: $spacer;
}
.product-line-info{
  
  &.product-price {
    .price {
      font-size: $h4-font-size;
    }
  }
}

.product-line-info-secondary {
  label {
    margin: 0;
  }
}
.price-with-no-tax {
  display: block;
  font-weight: $weight-light;
}

.promo-code-button{
  .collapse-button[aria-expanded=true] {
    display: none;
  }
}
.promo-highlighted{
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
  margin-bottom: $spacer/2;
}
.promo-discounts{
  font-weight: $font-weight-bold;
  text-align: center;
  color: $gray-600;
  .code{
    color: $tertiary;
  }
}
.form__add-voucher{
  margin-bottom: $spacer;
}
.promo-code{
  &__content{
    padding: 0 $card-spacer-x;
  }
 .collapse-button{
   display: block;
   color: $primary;
 }
}

.promo-code-button {
  margin-top: $spacer*1.5;
  font-weight: $weight-medium-regular;
  margin-bottom: $spacer/2;
}

.promo-name {
  margin-top: $spacer;
}

.promo-name, .promo-name span {
  font-weight: $weight-bold;
}

#js-checkout-summary {
  box-shadow: $box-shadow-sm;
  border-radius: $border-radius-lg;
  overflow: hidden;
  position:sticky;
  top:$spacer*10;
  min-height: calc(100vH - 220px);
  .card-footer {
    padding:0
  }

}

.cart-summary-products {
  font-weight: $weight-medium;
  color: $gray-500;
}