/* Product miniature */
$product-miniature-bg-color: null;
$product-miniature-border-width: null;
$product-miniature-border-color: null;
$product-miniature-min-height:     36rem;

/* Product title */
$product-miniature-name-color:                $text-black;
$product-miniature-name-hover-color:          $primary;
$product-miniature-name-font-size:            $h3-font-size;
$product-miniature-name-font-weight:          $weight-medium;

$product-miniature-highlight-bg-color: $white;
$product-miniature-highlight-translateY: 0;



.product-miniature {
    margin-bottom: $spacer * 2.5;

    .card {
        box-shadow: $box-shadow-md;
        overflow:hidden;
        border-radius: $border-radius-lg;
        @include transition-fast;

        &:hover {
        box-shadow: $box-shadow-lift-up;
        transform: translateY(-0.25rem);}
    }

    .pspc-main {
        position: absolute;
        width: 100%;
        bottom: -$spacer;
    }
}

.products {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
gap:$spacer*2}

.product-miniature {
    margin-bottom: $spacer;


    .product-thumbnail img {
        padding: $spacer $spacer/4;
        background: $white;
    }


    .product-title {
        font-size:$font-size-base;
        margin: $spacer/2 0;

    }
    
    .price {
        font-size:$font-size-base*0.875;
        font-weight: $weight-medium;
        &.variable::before {
            content: "od ";
            font-weight: 300;
        }
    }

    div.product-buttons {
        padding-bottom: $spacer/4;
        padding-top: $spacer;
        display: flex;
        align-items: center;
        form {width:100%}



        .view-more, .add-to-cart {
            padding: $spacer*0.75 $spacer/2;
            height: auto;
            position:relative;
            border-radius:$border-radius-lg;
            &:before {
                display:none
            }

            &:after, &:hover {
                background: $secondary;
                color: $white;
            }
                
        }

        .add-to-cart {
            background:$primary;
            color:$white;
            width:100%;
            text-transform: uppercase;
            font-size: $font-size-base*0.875;
            font-weight: $weight-medium-regular;
        }

        .view-more {
            border-radius: $spacer *2;
            text-align: center;
        }
    }


    .product-price-and-shipping {
        flex-direction: row;
        gap: 0.5rem;
        align-items: baseline;
    }

    .regular-price {
        font-size:$font-size-base*0.625;
        font-weight: $weight-medium;
        color:$gray-500;
    }

    .current-price-discount {
        color: $primary;
    }



}



/* Product miniature*/



.card-product {
    height: 100%;
    position: relative;
    background-color: $product-miniature-bg-color;
    border-width: $product-miniature-border-width;
    border-color: $product-miniature-border-color;

    
    
    .card-body {
        justify-content: space-between;
        flex-direction: column;
    }
    .discount {
        display: none;
    }

}


.product-flags {
    position: absolute;
    top: 0;
    margin-bottom: 0;
    z-index: 1;
    top: $spacer * 1.25;
    right: $spacer * 1.25;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.product-flag {
    top: 0;
    padding: $spacer * 0.875;
    color: $white;
    background: $secondary;
    font-weight: 300;


    &:not(:first-child) {
        margin-top: $spacer * .5;
    }
}

.product-miniature  { 
    a, a:hover {
        color:$off-black;
        font-weight: $weight-medium;}   


    .product_availability_wrap {
        font-size:$font-size-base*0.75;
        color: $green;
        text-transform:lowercase;
    
        span:not(:last-child) {
            position:relative;
        }

    }
    small {
            font-size: $font-size-base*0.75;
            text-transform: none;
            display: block;
            line-height: 1.25em;
        }

    .text-danger {
        text-transform: uppercase;
        font-weight: $weight-medium-regular;
        &.material-icons {
            margin-right: $spacer/4;
            font-size: $font-size-base*0.75;
        }
    }


    .product-flags {
        position: absolute;
        top: 0;
        left:0;
        width:100%;
        
        .product-flag {
            left: $spacer;
            top: $spacer;
            position: absolute;
            margin: 0;
            text-transform:lowercase;
            font-weight: $weight-regular;    
            padding: $spacer*0.4 $spacer*0.75;
            font-size: $font-size-base*0.75;
            border-radius: $btn-radius;
        }

        .discount-product, .pack{
            left: unset;
            right: $spacer;
            background:$secondary

        }

        .new {
            background:$white;
            color: $primary;
            font-size: $font-size-base*0.625;
            text-transform: uppercase;
            font-weight: $weight-medium;
        }

        .on-sale {
            background:none;
            color:$primary;
            text-transform: none;
            font-weight: $weight-medium;
            font-size: $font-size-base*0.625;
        }

        
    }
}


#product .pspc-main .pspc-time {
    box-shadow:none
}

.product-miniature .psproductcountdown {
    background-color: transparent!important;
    background: linear-gradient(0deg, #fffffff7 40%, transparent 75%)!important;
    height: 95%; 
}

#products .psproductcountdown .pspc-main .pspc-time span.pspc-count {
    background-color:$primary
}

#products .psproductcountdown .pspc-main .pspc-time .pspc-count, #product .psproductcountdown .pspc-main .pspc-time .pspc-count {
    background:$primary!important;
}

.product-miniature .pspc-wrp{
    height: 60%;
    opacity:1;
    @include transition-fast;
}

.product-miniature:hover .pspc-wrp {
    opacity:0
    
}

