@import "components/toastr.scss";

.cart-icon.add-to-cart {
    width: $size-base;
    height: $size-base;
    @include flex-center;
    position: relative;
    overflow: hidden;
    @include transition-slow;
    border: none;
    z-index: 10;
    &:before,
    &:after {
        @include before-wrap;

    }
    &:before {
        background: $btn-active-bg;
        z-index: -3;
    }
    &:after {
        background: $btn-bg;
        transform: translateX(-100%);
        @include transition(0.3s cubic-bezier(0.27, 0.17, 0.35, 1.62));
        z-index: -1;
    }
    &:hover:after,
    &:active:after,
    &:focus:after {
        transform: translateX(0%);
    }
    .shopping-cart-icon {
        color: $white;
        width: $icon-small-size;
        height: auto;
        z-index: 0;
        @include transition-slow;
    }
    .cart-spinner {
        position: absolute;
        pointer-events: none;
        opacity: 0;
        @include transition-slow;
        svg {
            width: $icon-normal-size;
            height: auto;
            stroke: $white;
        }
        svg,
        circle {
            animation-play-state: paused;
        }
    }
    .cart-check {
        position: absolute;
        pointer-events: none;
        z-index: -2;
        @include transition-slow;
        svg {
            width: $icon-small-size;
            height: $icon-small-size;
            stroke: $white;
        }
        polyline {
            opacity: 0;
            @include transition(opacity 0.15s, stroke-dashoffset 0.4s ease-out);
        }
    }
    .cart-x {
        position: absolute;
        pointer-events: none;
        z-index: -2;
        svg {
            width: $icon-small-size;
            height: $icon-small-size;
            stroke: $white;
        }
        line {
            opacity: 0;
            @include transition(opacity 0.15s, stroke-dashoffset 0.4s ease-out);
        }
    }
    &.loading,
    &.success,
    &.error {
        pointer-events: none;
        .shopping-cart-icon {
            opacity: 0;
            transform-origin: center;
            transform: scale(0.75);
        }
    }
    &.loading {
        &:after {
            transform: translateX(0%);
        }
        .cart-spinner {
            opacity: 1;
            svg,
            circle {
                animation-play-state: running;
            }
        }
    }
    &.success {
        &:before {
            background-color: $green;
        }
        &:after {
            transform: translateX(100%);
        }
        .cart-spinner {
            opacity: 0;
            svg,
            circle {
                animation-play-state: paused;
            }
        }
        .cart-check {
            polyline {
                opacity: 1;
                stroke-dashoffset: 0;
            }
        }
    }
    &.error {
        &:before {
            background-color: $red;
        }
        &:after {
            transform: translateX(100%);
        }
        .cart-spinner {
            opacity: 0;
            svg,
            circle {
                animation-play-state: paused;
            }
        }
        .cart-x {
            line {
                opacity: 1;
                stroke-dashoffset: 0;
            }
        }
    }
}

.cart-spinner {
    svg {
        position: relative;
        animation: rotate 2s linear infinite;
        transform-origin: center center;
    }

    circle {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: dash 1.5s ease-in-out infinite;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
