// Mobile menu breakpoint settings

$media-breakpoint-mobile-menu-up: xxl;
$media-breakpoint-mobile-menu-down: xl;

// Device breakpoint settings

$breakpoint-desktop: lg;
$breakpoint-tablet: md;
$breakpoint-mobile: sm;
$bp-desktop-lg-up:1601px;

$bp-desktop-xl:1880px;
$bp-desktop-lg:1600px;
$bp-desktop-md:1400px;
$bp-desktop-sm:1200px;
$bp-tablet:1024px;
$bp-mobile-lg:991px;
$bp-mobile-md:864px;
$bp-mobile-sm:767px;
$bp-mobile:670px;
$bp-mobile-xs:420px;


// Mobile menu breakpoint CSS variable for further JS usage.

:root {
    --menu-up-breakpoint: #{map-get($grid-breakpoints, $media-breakpoint-mobile-menu-up)};
}

// Variable device breakpoints

@mixin media-breakpoint-mobile-menu($scope: up) {
    @if ($scope == up) {
        @include media-breakpoint-up($media-breakpoint-mobile-menu-up) {
            @content;
        }
    }
    @if ($scope == only) {
        @media (min-width: map-get($grid-breakpoints, $media-breakpoint-mobile-menu-down)) and (max-width: map-get($grid-breakpoints, $media-breakpoint-mobile-menu-up)) {
            @content;
        }
    }
    @if ($scope == down) {
        @include media-breakpoint-down($media-breakpoint-mobile-menu-down) {
            @content;
        }
    }
}

@mixin media-breakpoint-desktop($scope: up) {
    $breakpoint: $breakpoint-desktop;
    @if ($scope == up) {
        @include media-breakpoint-up($breakpoint) {
            @content;
        }
    }
    @if ($scope == only) {
        @include media-breakpoint-only($breakpoint) {
            @content;
        }
    }
    @if ($scope == down) {
        @include media-breakpoint-down($breakpoint) {
            @content;
        }
    }
}

@mixin media-breakpoint-tablet($scope: down) {
    $breakpoint: $breakpoint-tablet;
    @if ($scope == up) {
        @include media-breakpoint-up($breakpoint) {
            @content;
        }
    }
    @if ($scope == only) {
        @include media-breakpoint-only($breakpoint) {
            @content;
        }
    }
    @if ($scope == down) {
        @include media-breakpoint-down($breakpoint) {
            @content;
        }
    }
}

@mixin media-breakpoint-mobile($scope: down) {
    $breakpoint: $breakpoint-mobile;
    @if ($scope == up) {
        @include media-breakpoint-up($breakpoint) {
            @content;
        }
    }
    @if ($scope == only) {
        @include media-breakpoint-only($breakpoint) {
            @content;
        }
    }
    @if ($scope == down) {
        @include media-breakpoint-down($breakpoint) {
            @content;
        }
    }
}
