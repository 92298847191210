$search-btn-size: $spacer * 2.5;
$search-box-padding: $spacer * 0.3125;
@media (max-width:$bp-mobile-lg) {
  .search-bar-mobile {
    display: flex;
    align-items: center;
    position: relative;
  }

  .search-input {
    width: 100% !important;
    opacity: 1;
  }
}

.search-widget {
  height: $search-btn-size + ($search-box-padding * 2);
  width: $search-btn-size + ($search-box-padding * 2);
  position: relative;
  z-index: 1;
  @media (max-width:$bp-mobile-lg) {
    // &:not(.open) {
    //   max-width: 0;
    //   overflow: hidden;
    // }

    // &.open {
    //   width: 100%;
    //   max-width: 100%;
    //   overflow: visible;
    //   position: fixed;
    //   top: $spacer * 6;
    //   right: 0;
    //   z-index: 999999;
    //   div.search-input {
    //       width: $spacer * 20;
    //     }

    //   .search-box {
    //     width: calc(100% - 1.5rem);
    //     left: 0.75rem;
    //     z-index: 9999;
    //   }
   
    //     .search-close {
    //       position: absolute;
    //       right: $spacer*1.5;
    //       font-size: $spacer * 1.5;
    //       color: $primary;
    //       z-index: 9999999;
    //       height: 100%;
    //       display: flex;
    //       align-items: center;
    //     }
    // }
    
    // svg.icon-mobile-search {
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    // }

    

    
  

  
  @media (max-width:$bp-mobile-md) {
    height: $spacer*2.5;
  }

  }
   
}
.search-widget__group {
  position: relative;
}
.search-widget__input-right {
  padding-right: $spacer * 3.125;
}
.search-widget__btn {
  position: absolute;
  right: 0;
  top: 0;
}

.search-btn {
  width: $search-btn-size;
  height: $search-btn-size;
  @include border-radius(50%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @include transition-fast;
  border: none;
  background: $gray-100;
  &[disabled] {
    pointer-events: none;
  }

  @media (max-width: $bp-mobile-md) {
    height: $spacer * 2;
  }
}

.search-box {
  position: absolute;
  @include border-radius(3.125rem);
  padding: $search-box-padding;
  display: flex;
  background: $gray-100;
  @media (max-width: $bp-mobile-md) {
    height: $spacer * 2.5;
  }


}

@include media-breakpoint-down(md) {
  .search-widget {
    width: 100%;
    margin: $spacer/2 $spacer $spacer/2 0;
  }
  .search-box {
    position:relative
  }

  .icons__menu {
    font-weight: $weight-medium-regular;
    svg {
    width: $spacer*1.25;
    height: $spacer*1.25;
    margin-bottom: $spacer/5;
    fill:$primary;
    }
  }

}

header .search-box {
  right: 0;
}
/* input */
.search-input {
  outline: none;
  border: none;
  background: none;
  width: 0;
  padding: 0;
  float: left;

  opacity: 0;
  @include transition-fast;

  font-size: $font-size-base;
  line-height: 2.5rem;
  color: $black;
}
.search-input::placeholder {
  color: $text-gray;
}
@include media-breakpoint-desktop {
  .search-input:focus,
  .search-input:not(:placeholder-shown),
  .search-box > .search-input {
    width: 30rem;
    padding: 0 $spacer;
    opacity: 1;
  }
}

.search-box:hover > .search-input,
.search-input:focus,
.search-input:not(:placeholder-shown) {
  opacity: 1;
}

.search-btn:not([disabled]):hover {
  background-color: $white;
}

@include media-breakpoint-mobile {
  .search-input:focus,
  .search-input:not(:placeholder-shown),
  .search-box:hover > .search-input {
    width: auto;
    padding: 0 $spacer;
  }

  .search-input button {
    @include transition-fast;
  }

  .search-input:focus ~ button,
  .search-input:not(:placeholder-shown) ~ button,
  .search-box:hover > .search-input ~ button {
    max-width: 0;
    opacity: 0;
    padding: 0;
  }
}

// @media (min-width:1200px) {

//   .search-box:hover > .search-input,
// .search-input:focus,
// .search-input:not(:placeholder-shown){
//   opacity: 1;
// }
// .search-input {
//   width: 100%;
//   max-width: 15rem;
//   padding: 0 1rem;

// }

// }
